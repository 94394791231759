
import { defineComponent, ref, onMounted } from "vue";
import KTDatatable from "@/components/kt-datatable/KTDatatable.vue";
import KTNewDeviceModal from "@/components/modals/forms/NewDeviceModal.vue";
import KTPdfModal from "@/components/modals/forms/PdfModal.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import {
  deleteInvoice,
  getCustomersList,
  invoiceListing,
} from "@/store/api/devices";
import moment from "moment";
import { useRouter } from "vue-router";
interface IPagination {
  page_number: number;
  page_size: number;
  customer_id: string;
  filters: {
    name: string;
  };
}
export default defineComponent({
  name: "invoice",
  components: {
    KTDatatable,
    KTNewDeviceModal,
    KTPdfModal,
  },
  setup() {
    const invoices = ref<any[]>([]);
    const router = useRouter();
    const loading = ref<boolean>(true);
    const search = ref<string>("");
    const customers = ref<any[]>([]);
    const headerConfig = ref([
      {
        name: "Serial No.",
        key: "id",
      },
      {
        name: "Customer Name",
        key: "se_account_id",
      },
      {
        name: "Invoice Date	",
        key: "invoice_date",
      },
      {
        name: "Due Date",
        key: "due_date",
      },
      {
        name: "Delivery Date",
        key: "delivery_date",
      },
      {
        name: "Po Number",
        key: "po_number",
      },
      {
        name: "Status",
        key: "status",
      },
      {
        name: "Action",
        key: "action",
      },
    ]);
    const total = ref<number>(0);

    const selectedInvoice = ref<any>();
    const customer = ref<any>();

    const pagination = ref<IPagination>({
      page_number: 1,
      customer_id: "",
      page_size: 10,
      filters: {
        name: "",
      },
    });

    const permissions = ref<any>("");

    onMounted(async () => {
      permissions.value = localStorage.getItem("permissions");
      permissions.value = JSON.parse(permissions.value);
      getCustomersList().then((response) => {
        customers.value = response;
        loadData();
      });
      setCurrentPageBreadcrumbs("Invoice", [""]);
    });

    const loadData = async () => {
      loading.value = true;
      invoiceListing(pagination.value).then((response) => {
        invoices.value = response.invoices;
        total.value = response.total_number_of_records;
        loading.value = false;
      });
    };

    const currentChange = (val) => {
      pagination.value.page_number = val;
      loadData();
    };

    const onCustomerChange = (event) => {
      pagination.value.page_number = 1;
      pagination.value.customer_id = event.target.value;
      loadData();
    };

    const searchItems = () => {
      pagination.value.page_number = 1;
      if (search.value !== "") {
        pagination.value.filters.name = search.value;
      } else {
        pagination.value.filters.name = "";
      }
      loadData();
    };

    const itemsPerPageChange = (val) => {
      pagination.value.page_size = val;
      loadData();
    };

    const dateTime = (value) => {
      return moment(value).format("MMM DD , YYYY");
    };

    const createInvoice = () => {
      router.push("/apps/billing/create");
    };

    const editInvoice = (invoice) => {
      let s = JSON.stringify(invoice);
      router.push(`/apps/billing/update/${invoice.id}`);
    };

    const deleteDevice = (id) => {
      loading.value = true;
      deleteInvoice(id).then((r) => {
        loading.value = false;
        loadData();
      });
    };

    const openInvoice = (invoice) => {
      let company;
      customers.value.find((e) => {
        if (e.id == invoice.se_account_id) {
          company = e;
        }
      });
      selectedInvoice.value = invoice;
      customer.value = company;
    };

    return {
      headerConfig,
      invoices,
      total,
      loading,
      search,
      pagination,
      currentChange,
      searchItems,
      itemsPerPageChange,
      onCustomerChange,
      dateTime,
      customers,
      createInvoice,
      editInvoice,
      deleteDevice,
      selectedInvoice,
      customer,
      openInvoice,
      permissions,
    };
  },
});
