
import { defineComponent, onMounted, ref, watch } from "vue";
import jspdf from "jspdf";
import html2canvas from "html2canvas";
import moment from "moment";

export default defineComponent({
  name: "pdf-modal",
  // emit: ["reload"],
  props: {
    invo: { required: true },
    customer: { required: true },
  },
  components: {},
  setup(props, { emit }) {
    // const devicesList = ref(props.devices);
    const company = ref<any>({});
    const invoice = ref<any>(props.invo);
    const list = ref<any>({});

    // const customer_id = ref(props.customer_id);
    // let response: any = {};
    onMounted(async () => {
      // list.value = JSON.parse(invoice.value.json);
    });

    watch(
      () => props.customer,
      (first) => {
        company.value = first;
      }
    );

    watch(
      () => props.invo,
      (first) => {
        invoice.value = first;
        list.value = JSON.parse(invoice.value.json);
      }
    );

    const dateTime = (value) => {
      return moment(value).format("MMM DD , YYYY");
    };

    const getCurrency = (currency) => {
      let str = "";
      if (currency == "NOK") {
        str = "NOK";
      } else if (currency == "USD") {
        str = "$";
      } else {
        str = "€";
      }
      return str;
    };

    function getElement(el: Element) {
      return el;
    }

    const printDocument = () => {
      var data = document.getElementById("invoiceTemplate") as HTMLInputElement;
      if (data == null) return false;
      html2canvas(data).then((canvas) => {
        // Few necessary setting options

        const contentDataURL = canvas.toDataURL("image/png");
        var margin = 2;
        var imgWidth = 210 - 2 * margin;
        var pageHeight = 295;
        var imgHeight = (canvas.height * imgWidth) / canvas.width;
        var heightLeft = imgHeight;

        var doc = new jspdf("p", "mm");
        var position = 0;

        doc.addImage(
          contentDataURL,
          "PNG",
          margin,
          position,
          imgWidth,
          imgHeight
        );

        heightLeft -= pageHeight;

        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          doc.addPage();
          doc.addImage(
            contentDataURL,
            "PNG",
            margin,
            position,
            imgWidth,
            imgHeight
          );
          heightLeft -= pageHeight;
        }
        var footer = document.getElementById("footer");
        if (footer == null) return false;
        html2canvas(footer).then((c) => {
          // Few necessary setting options
          const footer = c.toDataURL("image/png");
          var imgHeight = (c.height * imgWidth) / c.width;
          doc.addPage();
          doc.addImage(footer, "PNG", margin, position, imgWidth, imgHeight);
          doc.save(`0000${invoice.value.id}_${company.value.name}.pdf`);
        });
      });
    };

    const discardModal = () => {
      document.getElementById("kt_modal_pdf_cancel")?.click();
    };

    const isObject = (val: boolean) => {
      return typeof val === "object";
    };

    return {
      isObject,
      discardModal,
      list,
      invoice,
      company,
      printDocument,
      dateTime,
      getCurrency,
    };
  },
});
